import React, {useState} from 'react';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
const pdfFile = 'https://www.buergerstiftung-kreis-rv.de/downloads/BSKRV-SB2023_ANSICHT.pdf';
//const pdfFile = 'http://localhost:3000/downloads/BSKR_Stifterbrief_3_2022_ONLINE.pdf';


//This is for Windows dev don't Push this!
/*------------------------------------

let Document,Page = null;
if (typeof document !== "undefined") {
    Document = require( 'react-pdf').Document;
    Page = require( 'react-pdf').Page;
}


*/

export default function StifterPDF() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return (
        <div>
            <div className={"pdf_view_page"}>
                <Button
                    circular
                    icon='angle left'
                    className={"pdf_view_prev"}
                    onClick={() => {
                        if (pageNumber !== 1)
                            setPageNumber((pageNumber - 1))
                    }}
                />
                <p className={"pdf_view_wrapper"}> {pageNumber} von {numPages}</p>
                <Button
                    circular
                    icon='angle right'
                    className={"pdf_view_next"}
                    onClick={() => {
                        if (pageNumber !== numPages)
                            setPageNumber((pageNumber + 1))
                    }}
                />
            </div>
            <Document
                className={"pdf_document"}
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber}/>
            </Document>
            <Button
                circular
                className={"download_button"}
                onClick={() => window.open(pdfFile, "_blank")}>
                DOWNLOAD
            </Button>
        </div>
    );
}